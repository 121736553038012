<template>
  <div>
    <v-toolbar
      app
      :class="{
        'px-4': $vuetify.breakpoint.lgAndUp,
        'px-3': $vuetify.breakpoint.mdAndDown
      }"
      :flat="toolbar_is_flat"
      :color="toolbar_color"
      class="animation-toolbar"
    >
      <v-img
        :aspect-ratio="16/9"
        height="50"
        max-width="200"
        contain
        class="mx-2 clickable ma-auto"
        :src="toolbar_logo"
        :lazy-src="toolbar_logo"
        @click="changeRoute('/', 'goto_landing')"
      ></v-img>

      <v-spacer></v-spacer>

      <v-btn v-if="$vuetify.breakpoint.smAndDown" icon @click="drawer = true" class="ma-0"><v-icon :color="toolbar_text_color">menu</v-icon></v-btn>

      <v-toolbar-items class="hidden-sm-and-down">
        <template v-for="item in menu">
          <v-btn
            flat
            :color="toolbar_text_color"
            v-if="!item.children && show(item.show)"
            class="normalcase graphik-medium"
            @click="item.action()"
            :key="item.title"
            :ripple="false"
          >{{ item.title }}</v-btn>

          <v-menu
            :nudge-width="100"
            v-else-if="item.children && show(item.show)"
            :key="item.title"
          >
            <v-btn
              flat
              :color="toolbar_text_color"
              class="normalcase graphik-medium"
              slot="activator"
              :ripple="false"
            >
              <span>{{ item.title }}</span>
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>

            <v-list>
              <template v-for="child in item.children">
                <v-list-tile
                  v-if="show(child.show)"
                  class="normalcase"
                  @click="child.action()"
                  :key="child.title"
                  :ripple="false"
                >
                  <v-list-tile-title v-text="child.title"></v-list-tile-title>
                </v-list-tile>
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      right
      app
      temporary
    >
      <v-card flat tile color="light-background">
        <v-card-title class="primary pt-2 pb-3"></v-card-title>

        <v-card-text class="pa-0 min-height-menu">
          <v-list>
            <template v-for="item in menu">
              <v-list-tile avatar v-if="!item.children && show(item.show)" :key="item.title">
                <v-list-tile-avatar tile>
                  <v-img
                    :src="item.icon"
                    height="25px"
                    :lazy-src="item.icon"
                  />
                </v-list-tile-avatar>

                <v-list-tile-content
                  class="dark-grey--text graphik-light"
                  @click="item.action()"
                >
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-group v-else-if="item.children && show(item.show)" :key="item.title">
                <template v-slot:activator>
                  <v-list-tile avatar>
                    <v-list-tile-avatar tile>
                      <v-img
                        :src="item.icon"
                        height="25px"
                        :lazy-src="item.icon"
                      />
                    </v-list-tile-avatar>

                    <v-list-tile-content
                      class="dark-grey--text graphik-light"
                    >{{ item.title }}</v-list-tile-content>
                  </v-list-tile>
                </template>

                <v-list-tile avatar v-for="child in item.children" :key="item.title + child.title">
                  <v-list-tile-avatar>

                  </v-list-tile-avatar>

                  <v-list-tile-content
                    class="dark-grey--text graphik-light"
                    @click="child.action()"
                  >
                    <v-list-tile-title>{{ child.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </template>
          </v-list>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-img
            contain
            :aspect-ratio="9/16"
            max-height="150px"
            height="100%"
            width="100%"
            :src="images.cuco_up"
            :lazy-src="images.cuco_up"
          />
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  computed: {
    toolbar_logo () {
      return (this.mobile_header_text || this.$route.meta.header_text) === 'secondary' ? this.images.logo_purple : this.images.logo_white
    },
    toolbar_color () {
      return this.mobile_header_background || this.$route.meta.header_background || 'primary'
    },
    toolbar_text_color () {
      return this.mobile_header_text || this.$route.meta.header_text || 'white'
    },
    toolbar_is_flat () {
      return this.scroll < 101
    },
    mobile_header_text () {
      if (
          this.$vuetify.breakpoint.xsOnly && 
          (
            this.$route.name === 'Landing' ||
            this.$route.name === 'Thankyou' ||
            this.$route.name === 'ConfirmEmail'
          )
        ) return 'white'

      if (
          this.$vuetify.breakpoint.xsOnly && 
          (
            this.$route.name === 'PrivacyPolicy' ||
            this.$route.name === 'Terms' ||
            this.$route.name === 'FAQ' ||
            this.$route.name === 'Companies' ||
            this.$route.name === 'Sinisters' ||
            this.$route.name === undefined
          )
        ) return 'secondary'

      return ''
    },
    mobile_header_background () {
      if (
          this.$vuetify.breakpoint.xsOnly && 
          (
            this.$route.name === 'Landing' ||
            this.$route.name === 'Thankyou' ||
            this.$route.name === 'ConfirmEmail'
          )
        ) return 'primary'

      if (
          this.$vuetify.breakpoint.xsOnly && 
          (
            this.$route.name === 'Login' ||
            this.$route.name === 'Register' ||
            this.$route.name === 'ProfileUser' ||
            this.$route.name === 'ProfilePolicies' ||
            this.$route.name === 'PolicySearch'  ||
            this.$route.name === 'PrivacyPolicy' ||
            this.$route.name === 'Terms' ||
            this.$route.name === 'ResetPassword' ||
            this.$route.name === 'Policy' ||
            this.$route.name === 'HowItWorks' ||
            this.$route.name === 'FAQ' ||
            this.$route.name === 'Companies' ||
            this.$route.name === 'Sinisters' ||
            this.$route.name === 'Issuance' ||
            this.$route.name === 'ProductCar' ||
            this.$route.name === 'ProductFleet' ||
            this.$route.name === 'ProductHouse' ||
            this.$route.name === 'ProductLife' ||
            this.$route.name === 'ProductUber' ||
            this.$route.name === undefined
          )
        ) return 'light-background'

      if (
          this.$vuetify.breakpoint.smAndDown && 
          (this.$route.name === 'Policy')
        ) return 'light-background'

      return ''
    }
  },
  data () {
    return {
      scroll: 0,
      drawer: false,
      images: {
        cuco_up: require('@/assets/img/images/cuco_looking_up_mobile.png'),
        logo_white: require('@/assets/img/brand/logo-white.png'),
        logo_purple: require('@/assets/img/brand/logo-purple.png')
      },
      menu: [
        // {
        //   title: this.$ml.get('header_title_quote'),
        //   show: true,
        //   icon: require('@/assets/img/icons/blue/header_quote.png'),
        //   action: () => this.changeRoute('/', 'goto_quote')
        // },
        {
          title: this.$ml.get('header_title_quote'),
          icon: require('@/assets/img/icons/blue/header_products.png'),
          show: true,
          children: [
            {
              title: this.$ml.get('header_title_car'),
              show: true,
              action: () => this.changeRoute('/auto', 'goto_car')
            },
            {
              title: this.$ml.get('header_title_house'),
              show: true,
              action: () => this.changeRoute('/hogar', 'goto_house')
            },
            {
              title: this.$ml.get('header_title_fleet'),
              show: true,
              action: () => this.changeRoute('/flotillas', 'goto_fleet')
            },
            {
              title: this.$ml.get('header_title_uber'),
              show: true,
              action: () => this.changeRoute('/uber', 'goto_uber')
            },
            {
              title: this.$ml.get('header_title_life'),
              show: true,
              action: () => this.changeRoute('/vida', 'goto_life')
            }
          ]
        },
        {
          title: this.$ml.get('header_title_help'),
          icon: require('@/assets/img/icons/blue/header_help.png'),
          show: true,
          children: [
            {
              title: this.$ml.get('header_title_howitworks'),
              show: true,
              action: () => this.changeRoute('/comofunciona', 'goto_howitworks')
            },
            {
              title: this.$ml.get('header_title_faq'),
              show: true,
              action: () => this.changeRoute('/preguntasfrecuentes', 'goto_faq')
            },
            {
              title: this.$ml.get('header_title_sinister'),
              show: true,
              action: () => this.changeRoute('/siniestros', 'goto_sinisters')
            }
          ]
        },
        {
          title: this.$ml.get('header_title_search'),
          show: true,
          icon: require('@/assets/img/icons/blue/header_search.png'),
          action: () => this.changeRoute('/buscar/poliza', 'goto_searchpolicy')
        },
        {
          title: this.$ml.get('header_title_profile'),
          show: 'auth',
          icon: require('@/assets/img/icons/blue/header_profile.png'),
          action: () => this.changeRoute('/perfil', 'goto_profile')
        },
        {
          title: this.$ml.get('header_title_login'),
          show: 'unauth',
          icon: require('@/assets/img/icons/blue/header_profile.png'),
          action: () => this.changeRoute('/iniciarsesion', 'goto_login')
        }
      ]
    }
  },
  methods: {
    changeRoute (route, event) {
      if (event) {
        this.$analytics(event)
      }
      this.drawer = false
      this.$router.push(route)
    },
    getScrollHeight () {
      this.scroll = document.body.getBoundingClientRect().top * -1
    },
    openWindow (url) {
      window.open(url, '_blank')
    },
    show (show) {
      return (show && typeof show === 'boolean') || (show && typeof show === 'string' && ((show === 'auth' && this.g_auth.access_token) || (show === 'unauth' && !this.g_auth.access_token)))
    }
  },
  created () {
    window.addEventListener('scroll', this.getScrollHeight)
  },
  destroyed () {
    window.removeEventListener('scroll', this.getScrollHeight)
  }
}
</script>

<style scoped>
  .min-height-menu {
    min-height: calc(100vh - 174px)
  }
</style>


